import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import { ref } from 'vue';
import axios from 'axios';

const app = createApp(App);

if (process.env.NODE_ENV === 'development') {
   axios.defaults.baseURL = 'http://localhost:10001'
}
 else{
  axios.defaults.baseURL = 'http://preview.ecustmba2023.site/api'
}

// 全局挂载 axios
app.config.globalProperties.$axios = axios
app.config.globalProperties.$router = router
app.use(router);
app.mount('#app');
