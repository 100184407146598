import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '2023MBA非全2班班级系统',
    },
  },
  {
    name: 'news',
    path: '/news',
    component: () => import('./view/news/list'),
    meta: {
      title: '消息列表',
    },
  },
  {
    name: 'newsDetail',
    path: '/news/detail',
    component: () => import('./view/news/detail'),
    meta: {
      title: '消息详情',
    },
    
  },
  {
    name: 'photo',
    path: '/photo',
    component: () => import('./view/photo/index'),
    meta: {
      title: '相册',
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user/index'),
    meta: {
      title: '用户',
    },
  },
  {
    name: 'schedule',
    path: '/schedule',
    component: () => import('./view/schedule/index'),
    meta: {
      title: '日程',
    },
  },
  {
    name: 'test',
    path: '/test',
    component: () => import('./view/test/index'),
    meta: {
      title: '测试',
    },
  }
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
